
// Pricing Page content

export const pricingTitle = 'TheRootApp Command&Control center from the future.';
export const pricingSubtitle = 'Are you tired of constantly relying on IT staff to keep your business running smoothly? It\'s time to break free from the daily grind of technical challenges and embrace a more strategic approach to your IT operations. Introducing our game-changing solution: TheRootApp!';

// Categories must be a "Cloud" or "Host". It's required property. Position does not matter.
// Price as "Not a Number" value equals "Coming Soon" value
export const pricingPacks = [
  {
    category: 'Cloud',
    title: 'theRoot',
    description: 'theRoot subscription',
    price: null,
    inviteBtn: 'Sign-up for early access',
    inviteLink: 'mailto:signup@theroot.app?subject=Would like to join TheRoot waiting list',
    conditions: []
  },
  // {
  //   category: 'Cloud',
  //   title: 'TheRoot Pro',
  //   description: 'For teams and businesses',
  //   price: null,
  //   inviteBtn: 'Sign-up for early access',
  //   inviteLink: 'mailto:signup@theroot.app?subject=Would like to join TheRoot waiting list',
  //   conditions: []
  // }
];

import React from 'react';
import {Container} from "react-bootstrap";

import {pricingSubtitle, pricingTitle} from "../_pagesContent/PricingPage";
import PricingCalculator from "../components/PricingCalculator";

const PricingPage = () => {
  return (
    <Container className="pricing-page">
      <Container className="container-middle">
        <h1 className="page-title">
          {pricingTitle}
        </h1>
        <div className="page-subtitle">
          {pricingSubtitle}
        </div>

        <PricingCalculator />
      </Container>
    </Container>
  );
};

export default PricingPage;

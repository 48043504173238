import img5 from "../assets/tmp/img_5.webp";
import img6 from "../assets/tmp/img_6.webp";
import img7 from "../assets/tmp/img_7.webp";


// Are you TheRoot content

export const areYouTheRootLabel = 'TheRootApp C&C Hub';
export const areYouTheRootTitle = 'TheRootApp C&C Hub, Pioneering the Future of Control Centers!';
export const areYouTheRootSubtitle = 'Say Farewell to IT Hassles: Liberating You from Daily Tech Hurdles, theRooT Empowers a Strategic Shift in Your Business Operations. Unleash the Game-Changer!';

export const areYouTheRootContent = [
  {
    label: 'Introducing TheRootApp: Simplify, Streamline, Succeed!',
    title: 'Transforming IT Administration',
    description: 'Say goodbye to the hassle of burdening your system administrators with intricate project role assignments. With TheRootApp, we empower project managers by streamlining and automating the process of managing project roles, freeing up valuable time for your IT professionals to focus on more strategic initiatives.',
    content: [
      {
        faIcon: 'fa fa-rocket',
        name: 'Simplify Your IT Administrative Workflow:',
        desc: 'Managing user roles and project assignments has never been easier. TheRootApp centralizes IT administrative tasks, allowing administrators to seamlessly view, assign, and manage roles across projects with a few clicks—no more tedious manual processes.',
        image: img5
      },
      {
        faIcon: 'fa fa-cog',
        name: ' Swift, Informed Actions for IT Success:',
        desc: 'TheRootApp equips IT teams to handle assignments, review roles, and maintain user access effortlessly, ensuring smooth and efficient operations. Say goodbye to administrative chaos and hello to streamlined IT management.',
        image: img5
      },
      {
        faIcon: 'fa fa-usd',
        name: ' Reimagine IT Administration:',
        desc: 'Don\'t let IT complexities slow you down. Choose TheRootApp to simplify workflows, improve productivity, and transform your IT administration into a strategic advantage.',
        image: img5
      }
    ]
  },
  {
    label: 'Empowering Project Managers with Seamless Role Management',
    title: ' Your Ultimate Project Management Solution!',
    description: 'Are you grappling with the complexity of managing multiple projects and company members within your organization? Say goodbye to the headaches of administrative chaos and say hello to TheRootApp, the state-of-the-art Software as a Service (SaaS) that redefines Project Management.',
    content: [
      {
        faIcon: 'fa fa-refresh',
        name: 'Streamline Project Role Management:',
        desc: 'TheRootApp revolutionizes project management by eliminating the complexity of role assignments. It empowers project managers with intuitive tools to take full ownership of project roles, enabling IT professionals to dedicate their efforts to strategic priorities that propel your business forward.',
        image: img6
      },
      {
        faIcon: 'fa fa-user',
        name: 'Effortless Workflow Coordination:',
        desc: 'TheRootApp gives project managers complete visibility and control over role assignments, ensuring decisions are made swiftly and accurately. This streamlined approach enhances collaboration and drives efficiency across teams.',
        image: img6
      },
      {
        faIcon: 'fa fa-line-chart',
        name: 'Achieve Excellence in Project Delivery:',
        desc: 'Join the growing community of project managers who use TheRootApp to simplify workflows, enhance productivity, and deliver exceptional outcomes. TheRoot is the strategic asset your projects need to reach their full potential.',
        image: img6
      }
    ]
  },
  {
    label: 'Elevating Security Oversight',
    title: 'Your Ultimate Security Solution!',
    description: 'Are you concerned about the alignment of your company security assignments with its stringent policies and practices? Fear not! TheRootApp is here to empower your information security staff with an intuitive Software as a Service (SaaS) solution, designed to simplify security audits.',
    content: [
      {
        faIcon: 'fa fa-cloud-upload',
        name: 'Streamlined Security Audits:',
        desc: 'TheRootApp simplifies security compliance by centralizing role and access management. Information security teams can quickly assess and verify assignments, ensuring alignment with critical company policies.',
        image: img7
      },
      {
        faIcon: 'fa fa-bell-o',
        name: 'Policy Alignment Made Simple:',
        desc: 'Ensure every security assignment meets your organization\'s stringent standards. TheRootApp highlights inconsistencies and enables prompt corrections, making audits faster and more accurate.',
        image: img7
      },
      {
        faIcon: 'fa fa-refresh',
        name: 'Fortify Your Security Posture!',
        desc: 'With TheRootApp, elevate your organization\’s defenses by ensuring your security practices are always aligned and up-to-date. Experience peace of mind with seamless oversight and unmatched accuracy.',
        image: img7
      }
    ]
  }
];

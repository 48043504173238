import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Card, Button } from 'react-bootstrap';

import IconJira from "../assets/icons/pricing/jira.svg";
import IconConfluence from "../assets/icons/pricing/confluence.svg";
import IconBitbucket from "../assets/icons/pricing/bitbucket.svg";
import IconGitlab from "../assets/icons/pricing/gitlab.svg";
import IconGithub from "../assets/icons/pricing/github.svg";

const priceTable = {
	A: { users: 10, prices: [5.45, 4.45, 3.45, 2.45, 2] },
	B: { users: 100, prices: [5.45, 4.45, 3.45, 2.45, 2] },
	C: { users: 200, prices: [5.25, 4.25, 3.25, 2.25, 2] },
	D: { users: 500, prices: [4.65, 3.65, 2.65, 2.15, 2] },
	E: { users: 800, prices: [4.25, 3.25, 2.25, 2.15, 2] },
	F: { users: 1000, prices: [3.45, 2.45, 2, 2, 2] },
};

const softwareOptions = [
	{ icon: IconJira, name: 'Jira', disabled: false },
	{ icon: IconConfluence, name: 'Confluence', disabled: false },
	{ icon: IconBitbucket, name: 'Bitbucket', disabled: false },
	{ icon: IconGitlab, name: 'GitLab', disabled: false },
	{ icon: IconGithub, name: 'GitHub', disabled: false },
];

const INTEGRATIONS_COUNT = softwareOptions.length

const calculatePrice = (userCount: number, softwareCount: number) => {
	let tier = 'A';
	for (const [key, value] of Object.entries(priceTable)) {
		if (userCount <= value.users) {
			tier = key;
			break;
		}
	}

	const prices = priceTable[tier as keyof typeof priceTable].prices;
	let totalPrice = 0;
	for (let i = 0; i < softwareCount && i < prices.length; i++) {
		totalPrice += prices[i] * userCount;
	}
	return totalPrice;
};

export default function PricingCalculator() {
	const [users, setUsers] = useState(10);

	const [selectedIntegrations, setSelectedIntegrations] = useState<boolean[]>(Array(softwareOptions.length).fill(false));
	selectedIntegrations[0] = true;

	const selectedSoftwareCount = selectedIntegrations.filter(Boolean).length;

	const [totalPrice, setTotalPrice] = useState(calculatePrice(users, selectedSoftwareCount));
	const [pricePerUser, setPricePerUser] = useState(totalPrice / users);

	const userPrice = isNaN(pricePerUser) ? Number('0').toFixed(2) : pricePerUser.toFixed(2)
	const total = totalPrice.toFixed(2)

	useEffect(() => {
		const newTotalPrice = calculatePrice(users, selectedSoftwareCount);
		setTotalPrice(newTotalPrice);
		setPricePerUser(newTotalPrice / users);
	}, [users, selectedSoftwareCount]);

	const toggleIntegration = (index: number) => {
		const newSelectedIntegrations = [...selectedIntegrations];
		newSelectedIntegrations[index] = !newSelectedIntegrations[index];
		setSelectedIntegrations(newSelectedIntegrations);
	};

	const updateUsersHandler = (evt: any) => {
		const res = Number(evt.target.value)
		if (res > 1000 || isNaN(res)) {
			return
		}

		setUsers(res)
	}

	const usersOnBlur = (evt: any) => {
		if (Number(evt.target.value) <= 0) {
			setUsers(1)
		}
	}

	return (
		<Container className="p-4 border border-primary rounded-4 relative">
			<Row className="g-4 relative">
				<Col md={8}>
					<h2 className="fw-bold d-inline">Calculate your price</h2>
					<div style={{marginTop: '4rem', marginBottom: '4rem'}}>
						<p className="mb-3">Select Software</p>
						<div className="d-flex gap-4">
							{softwareOptions.map(({icon, name, disabled}, idx) => {
								const cls = 'pricing-calculator__software-button'
								const isSelected = selectedIntegrations[idx]
								const isDisabled = disabled ? `${cls}--disabled` : ''
								const modifier = isSelected ? `${cls}--selected` : ''

								return (
								<div
									key={idx}
									onClick={() => {
										if (disabled) {
											return
										}
										toggleIntegration(idx)}
									}
									title={name}
									className={`pricing-calculator__software-button ${modifier} ${isDisabled} d-flex align-items-center justify-content-center`}
								>
									<img src={icon} className="w-100 h-100" alt={name}/>
								</div>
							)})}
						</div>
					</div>
					<Form.Group>
						<div className="d-flex justify-content-between mb-2">
							<div style={{textAlign: 'left', width: '30%'}}>
								<small className="text-muted">0</small>
							</div>
							<div style={{textAlign: 'center', width: '40%'}}>
								<small className="text-muted">500</small>
							</div>
							<div style={{textAlign: 'right', width: '30%'}}>
								<small className="text-muted">1000</small>
							</div>
						</div>
						<Form.Range
							value={users}
							onChange={updateUsersHandler}
							min={1}
							max={1000}
							step={1}
							className="pricing-calculator__gradient-range"
						/>
					</Form.Group>
				</Col>

				<Col>
					<Card bg="primary" text="white" className="rounded-4 border-0 pricing-calculator__form">
						<Card.Body>
							<Row className="g-3 mb-4">
								<Col xs={6}>
									<Form.Group>
										<Form.Label className="text-white">Users</Form.Label>
										<Form.Control
											type="number"
											value={users}
											onChange={updateUsersHandler}
											onBlur={usersOnBlur}
											min={1}
											max={1000}
											className="bg-white"
										/>
									</Form.Group>
								</Col>
								<Col xs={6}>
									<Form.Group>
										<Form.Label className="text-white">Integrations</Form.Label>
										<Form.Control
											type="number"
											value={selectedSoftwareCount}
											onChange={() => {}}
											min={1}
											max={INTEGRATIONS_COUNT}
											className="bg-white"
											disabled
										/>
									</Form.Group>
								</Col>
							</Row>

							<div className="mb-4">
								<small className="d-block text-white fw-medium">Average price per user</small>
								<h4 className="mb-0 text-white">${userPrice}/month</h4>
							</div>
							<hr />
							<div className="mb-4">
								<small className="d-block text-white fw-medium">Total price</small>
								<h3 className="mb-0 text-white">${total}/month</h3>
							</div>

							<Row className="g-3">
								{/*<Col xs={6}>*/}
								{/*	<Button variant="primary" className="w-100 fw-bold text-white">*/}
								{/*		Buy*/}
								{/*	</Button>*/}
								{/*</Col>*/}
								<Col xs={12}>
									<Button href="https://app.theroot.app/" target="_blank" rel="noopener noreferrer" variant="light" className="w-100 text-primary">
										Sign Up
									</Button>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}

import React from 'react'

export default function GetStarted({ children = 'Sign Up' }: { children?: React.ReactNode }) {
	return (
		<a href="https://app.theroot.app/auth/signup" target="_blank" rel="noopener noreferrer">
			<div className="btn btn-primary">
				{children}
			</div>
		</a>
	)
}